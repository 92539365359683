.about_heading_container {
  display: flex;
  flex-direction: column;
  gap: 1.2em;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: url(../images/About/aboutBg.svg);
  height: 50vh;
}

.about_content_text{
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.about_product-container{
    display: flex;
    flex-direction: column;
    gap: 4.5em;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #fafafa;
}

.sayanaAi-container, .our_values_container, .teams_container{
  display: flex;
  flex-direction: column;
  gap: 4em;
}

.sayanaAi-container{
  background: url(../images/About/sayanaBg.svg);
  background-position: center;
}

.our_values_container {
  justify-content: center;
  align-items: center;
  background: #F7F1E9;
}

.teams_container{
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #fafafa;
}


.teams_img{
  width: 100%;
}

.sliding__btns{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}


/* Carosel */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.gallery-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: max-content;
}

.buttons_container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.button-prev,
.button-next {
  font-family:"Material Icons";
  font-size: 2rem;
  cursor: pointer;
  user-select: none;
  border: 1px solid #111;
  border-radius: 50%;
}

.button-prev[disabled],
.button-next[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.gallery-track {
  aspect-ratio: 168/100;
  position: relative;
  width: 100%;
  height: max-content;
  overflow: hidden;
}

.slide-image {
  aspect-ratio: 168/100;
  position: absolute;
  background-size: contain;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

@keyframes slide-right-in {
    from {
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    to {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@keyframes slide-left-in {
    from {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
    }
    to {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@keyframes slide-right-out {
    from {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
    to {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
    }
}

@keyframes slide-left-out {
    from {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
    to {
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

.rightIn {
    -webkit-animation: slide-right-in 2s 1 forwards;
    -moz-animation: slide-right-in 2s 1 forwards;
    -ms-animation: slide-right-in 2s 1 forwards;
    -o-animation: slide-right-in 2s 1 forwards;
    animation: slide-right-in 2s 1 forwards;
}

.leftIn {
    -webkit-animation: slide-left-in 2s 1 forwards;
    -moz-animation: slide-left-in 2s 1 forwards;
    -ms-animation: slide-left-in 2s 1 forwards;
    -o-animation: slide-left-in 2s 1 forwards;
    animation: slide-left-in 2s 1 forwards;
}

.rightOut {
    -webkit-animation: slide-right-out 2s 1 forwards;
    -moz-animation: slide-right-out 2s 1 forwards;
    -ms-animation: slide-right-out 2s 1 forwards;
    -o-animation: slide-right-out 2s 1 forwards;
    animation: slide-right-out 2s 1 forwards;
}

.leftOut{
    -webkit-animation: slide-left-out 2s 1 forwards;
    -moz-animation: slide-left-out 2s 1 forwards;
    -ms-animation: slide-left-out 2s 1 forwards;
    -o-animation: slide-left-out 2s 1 forwards;
    animation: slide-left-out 2s 1 forwards;
}

@media(max-width: 1150px){
  .about_product-container img{
    width: 100%;
  }
}
@media(max-width: 950px){}
@media(max-width: 750px){}
@media(max-width: 550px){
  
}