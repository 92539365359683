@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  font-family: "Inter", sans-serif;
  scroll-behavior: smooth;
}

:root {
  /* Font Size */
  --hero-heading: 58px;
  --hero-text: 24px;

  --section-heading: 40px;
  --section-subheading: 20px;
  --section-text: 16px;

  --container-heading: 24px;
  --container-text: 16px;

  --button-text: 18px;

  /* Colors */
  --heading-color: #18191a;
  --text-color: #3a3b3c;

  --color-blue: #47acec;
  --color-yellow: #ffae00;
}

/* Scroll Bar */
body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(
    to bottom,
    rgba(255, 174, 0, 0.6),
    rgba(71, 172, 236, 0.6)
  );
}
/* End of Scroll Bar */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.main_container {
  position: absolute;
  top: 70px;
  width: 100%;
}

.section {
  padding: 1.8em 8em;
  overflow: hidden;
}

.content_container {
  display: flex;
  flex-direction: column;
}

.section__heading {
  font-size: var(--section-heading);
  font-weight: 700;
  color: var(--heading-color);
  text-transform: capitalize;
}

.section__subheading {
  font-size: var(--section-subheading);
  font-weight: 500;
  color: var(--heading-color);
  line-height: 1.4em;
}

.section__text {
  font-size: var(--section-text);
  font-weight: 400;
  color: var(--text-color);
  padding: 0 5em;
  line-height: 1.4em;
}

.container__heading {
  font-size: var(--container-heading);
  color: var(--heading-color);
}

.container__text {
  font-size: var(--container-text);
  color: var(--text-color);
}

.card_heading {
  font-size: 20px;
  color: var(--heading-color);
}

.section_heading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.6em;
}

.buttons_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  border: none;
  background: var(--color-yellow);
  font-size: 1em;
  padding: 0.6em 1.5em;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  cursor: pointer;
}
.btn2 {
  border: none;
  background: var(--color-blue);
  color: #fff;
  font-size: 1em;
  padding: 0.6em 1.5em;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  cursor: pointer;
}
.btn3 {
  border: none;
  background: none;
  font-size: 1em;
  padding: 0.6em 1.5em;
  border-radius: 50px;
  border: 1px solid #111;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  cursor: pointer;
}

.btn_link {
  color: #000;
}

.com_name {
  text-transform: uppercase;
}

.d-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loader_container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#47acec 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#47acec);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation:s3 1s infinite linear;
}

@keyframes s3{ 
  100%{transform: rotate(1turn)}
}

@media (max-width: 1150px) {
  .section {
    padding: 2em 7em;
  }
}
@media (max-width: 950px) {
  .section {
    padding: 2em 6em;
  }

  .section__text {
    padding: 0 1.5em;
  }

  .d-container{
    flex-direction: column;
    gap: 1.2em;
  }

  .d-container:nth-child(even){
    flex-direction: column-reverse;
  }
}
@media (max-width: 750px) {
  .section {
    padding: 2em 4em;
  }
}
@media (max-width: 550px) {
  .section {
    padding: 2em;
  }

  .section__heading {
    font-size: 32px;
  }

  .section__subheading {
    font-size: 18px;
  }

  .section__text {
    padding: 0;
  }

  .btn,
  .btn2,
  .btn3 {
    padding: 0.5em 0.9em;
  }

  .buttons_container {
    flex-direction: column;
    gap: 1em;
  }

  .buttons_container button {
    width: 100%;
  }
}

@media (max-width: 380px) {
  .section {
    padding: 1.5em;
  }
}
