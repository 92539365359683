/* Chatbot */
.botIcon {
  bottom: 20px;
  right: 20px;
  position: fixed;
  z-index: 9999;
}
.iconInner {
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  background: var(--color-blue);
  background-position: 50%;
  background-size: 300%;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 1.7em;
  height: 2em;
  justify-content: center;
  width: 2em;
}

.bot_img{
  width: 2em;
}

.iconInner::after, .iconInner::before{
  content: "";
  position: absolute;
  background: var(--color-blue);
  border-radius: 50%;
  height: 2em;
  width: 2em;
  z-index: -1;
  opacity: 0.7;
}

.iconInner::before{
  animation: notification 2s infinite ease-in-out;
}

.iconInner::after{
  animation: notification 2s 1s infinite ease-in-out;
}

@keyframes notification{
  100%{
    opacity: 0;
    transform: scale(2.5);
  }
}

.fa-robot{
  font-size: 0.85em;
  animation: upDown 2s infinite ease-in-out;
}

@keyframes upDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.iconInner:hover .fa-robot{
  animation: none;
}

.botSubject,
.messages,
.showBotSubject .botIconContainer,
.showMessenger .botIconContainer {
  display: none;
}

.botIcon .Messages,
.botIcon .Messages_list {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.chat_close_icon {
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 12px;
  z-index: 9;
}
.chat_on {
  background-color: #8a57cf;
  bottom: 20px;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  color: #fff;
  cursor: pointer;
  display: block;
  height: 45px;
  padding: 9px;
  position: fixed;
  right: 15px;
  text-align: center;
  width: 45px;
  z-index: 10;
}
.chat_on_icon {
  color: #fff;
  font-size: 25px;
  text-align: center;
}
.botIcon .Layout {
  -webkit-animation: appear 0.15s cubic-bezier(0.25, 0.25, 0.5, 1.1);
  -ms-animation: appear 0.15s cubic-bezier(0.25, 0.25, 0.5, 1.1);
  animation: appear 0.15s cubic-bezier(0.25, 0.25, 0.5, 1.1);
  -webkit-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  background-color: rgb(63, 81, 181);
  bottom: 20px;
  border-radius: 10px;
  box-shadow: 5px 0 20px 5px rgba(0, 0, 0, 0.1);
  box-sizing: content-box !important;
  color: rgb(255, 255, 255);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  max-height: 30px;
  max-width: 300px;
  min-width: 50px;
  opacity: 0;
  pointer-events: auto;
  position: fixed;
  right: 10px;
  -webkit-transition: left 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
  -ms-transition: left 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
  transition: left 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
  z-index: 999999999;
}
.botIcon .Layout-open {
  border-radius: 10px;
  color: #fff;
  height: 500px;
  max-height: 500px;
  max-width: 450px;
  overflow: hidden;
  -webkit-transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  -ms-transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  width: 100%;
}
.botIcon .Layout-expand {
  display: none;
  height: 400px;
  max-height: 100vh;
  min-height: 300px;
}
.showBotSubject.botIcon .Layout-expand {
  display: block;
}
.botIcon .Layout-mobile {
  bottom: 10px;
}
.botIcon .Layout-mobile.Layout-open {
  min-width: calc(100% - 20px);
  width: calc(100% - 20px);
}
.botIcon .Layout-mobile.Layout-expand {
  border-radius: 0 !important;
  bottom: 0;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}
.botIcon .Messenger_messenger {
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.botIcon .Messenger_header,
.botIcon .Messenger_messenger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.botIcon .Messenger_header {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--color-blue);
  color: rgb(255, 255, 255);
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 40px;
  padding-left: 10px;
  padding-right: 40px;
}

.botIcon .Messenger_header h4 {
  -webkit-animation: slidein 0.15s 0.3s;
  -ms-animation: slidein 0.15s 0.3s;
  animation: slidein 0.15s 0.3s;
  -webkit-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  font-size: 16px;
  opacity: 0;
}
.botIcon .Messenger_prompt {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.botIcon .Messenger_content {
  background-color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 80px;
}
.botIcon .Messages {
  background-color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  position: relative;
  -webkit-overflow-scrolling: touch;
}
.botIcon .Messages::-webkit-scrollbar {
  width: 0.5em;
}

.botIcon .Messages::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.botIcon .Messages::-webkit-scrollbar-thumb {
  background: linear-gradient(
    to bottom,
    rgba(71, 172, 236, 0.6),
    rgba(255, 174, 0, 0.6)
  );
}
.botIcon .Input {
  background-color: #fff;
  border-top: 1px solid #e6ebea;
  color: #96aab4;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-bottom: 15px;
  padding-top: 17px;
  position: relative;
  width: 100%;
}
.botIcon .Input-blank .Input_field {
  max-height: 20px;
}
.botIcon .Input_field {
  background-color: transparent;
  border: none;
  outline: none;
  padding-left: 20px;
  padding-right: 45px;
  resize: none;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  min-height: 20px !important;
}
.botIcon .Input_button-emoji {
  right: 45px;
}
.botIcon .Input_button {
  background-color: transparent;
  border: none;
  bottom: 15px;
  cursor: pointer;
  height: 25px;
  outline: none;
  padding: 0;
  position: absolute;
  width: 25px;
}
.botIcon .Input_button-send {
  right: 15px;
}
.botIcon .Input-emoji .Input_button-emoji .Icon,
.botIcon .Input_button:hover .Icon {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.botIcon .Input-emoji .Input_button-emoji .Icon path,
.botIcon .Input_button:hover .Icon path {
  fill: #2c2c46;
}
.Icon svg {
  height: auto;
  width: 100%;
}

.msg {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.msg.user {
  -webkit-box-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.msg + .msg {
  margin-top: 15px;
}

span.responsText {
  color: #000;
  padding: 0.65em;
  border-radius: 15px;
  display: inline-block;
  margin-left: 0.45em;
  vertical-align: top;
  max-width: calc(100% - 50px);
}

.msg.user span.responsText {
  margin-left: 0;
  margin-right: 0.45em;
}

span.avtr {
  display: inline-block;
  width: 30px;
}

.bot-message {
  color: var(--text-color);
  padding: 5px 10px;
  margin: 5px 0;
}

.msg.user .responsText {
  background-color: rgba(71, 172, 236, 0.6);
}

.msg.bot .responsText {
  background-color: rgba(255, 174, 0, 0.4);
}

.typing-indicator {
  display: flex;
  align-items: center;
}

.typing-indicator span {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin: 0 2px;
  background-color: var(--color-yellow);
  border-radius: 50%;
  opacity: 0;
  animation: blink 1.4s infinite both;
}

.typing-indicator span:nth-child(1) {
  animation-delay: 0s;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@-webkit-keyframes appear {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes appear {
  0% {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes appear {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes slidein {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes slidein {
  0% {
    opacity: 0;
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slidein {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media only screen and (max-width: 412px) {
  .botIcon {
    position: fixed;
    bottom: 1rem;
    right: 3rem;
  }

  .botIcon .Layout-open {
    width: 250px;
  }
}
