.footer_section{
    background: #F4F4F4;
    display: flex;
    flex-direction: column;
    gap: 1.4em;
    padding-bottom: 1em;
}

.footer{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 4em;
}

.company_name{
    display: flex;
    flex-direction: column;
    gap: 2.5em;
}

.logo_content img{
    width: 200px;
}

.footer_free_trail-container{
    background: url(../images/Bg.png) no-repeat;
    background-position: center;
    background-size: contain;
    width: max-content;
    padding: 2em;
}

.footer_free_trail, .location_container{
    display: flex;
    flex-direction: column;
    gap: 0.9em;
}

.footer_free_trail{
    border: 1px solid var(--color-yellow);
    padding: 1.3em;
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
}

.free_text{
    font-size: 1em;
}

.free_trail button{
    width: max-content;
    color: #fff;
}

.footer_Link{
    color: #111;
}

.footer_links, .footer_links_container{
    display: flex;
    flex-direction: column;
    gap: 0.8em;
}

.contact_container{
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.links_heading{
    font-size: 1em;
    display: flex;
    gap: 1em;
    align-items: center;
    width: max-content;
}

.footer_link, .location{
    font-size: 14px;
}

.footer_addr_container{
    padding-right: 4.8em;
}

.addr{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    border-top: 1px solid #111;
    padding: 1.2em 0;
    font-size: 14px;
}

.addr:nth-child(2){
    border-bottom: 1px solid #111;
}


.footer_links_container{
    gap: 0.6em;
    justify-content: flex-start;
    align-items: flex-start;
}

.contact__details{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 0.8em;
    font-size: 14px;
}

.copyright{
    margin-top: 2em;
    text-align: center;
}

@media(max-width: 1150px){}
@media(max-width: 950px){
    .footer{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2em 2.5em;
    }

    .footer_addr_container{
        padding-right: 0;
    }
}
@media(max-width: 750px){
    .footer{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media(max-width: 550px){
    .footer{
        grid-template-columns: repeat(1, 1fr);
    }

    .logo_content img{
        width: 160px;
    }
}